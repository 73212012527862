/* eslint-disable @typescript-eslint/no-non-null-assertion */
function lazyLoad() {
  [].forEach.call(document.querySelectorAll('img[data-src]'), function (img) {
    img.setAttribute('src', img.getAttribute('data-src'));
    img.onload = function () {
      img.removeAttribute('data-src');
    };
  });
}

export default function __main($, t) {
  ('use strict');
  lazyLoad();

  var MAIN = {
    guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4();
    },
    hexToRGBA(hex, alpha) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (!result) {
        return;
      }
      var colorObj = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: alpha || 1,
      };
      return 'rgba(' + colorObj.r + ', ' + colorObj.g + ', ' + colorObj.b + ', ' + colorObj.a + ')';
    },
  };

  // Caching Selectors
  var $body = $('body');
  var $window = $(window);
  var $document = $(document);
  var $navBar = $('#navbar');
  var $asideNav = $('#aside-nav');
  var $homeSlider = $('#home-slider');
  var $searchModal = $('#search-modal');
  var $worksGrid = $('#works-grid');
  var $firstSection = $('section:first');

  function initLoad() {
    $window.on('load', function () {
      $('#loader').delay(500).fadeOut();
      $('#mask').delay(1000).fadeOut('slow');

      // window.scrollTo({
      //   top: 0,
      //   behavior: 'instant'
      // });

      $worksGrid
        .isotope({
          itemSelector: '.work-item',
          masonry: {
            columnWidth: 0,
          },
        })
        .on('layoutComplete', function (event) {
          $window.trigger('resize');
        });

      $('.photo-gallery.masonry-style').justifiedGallery({
        rowHeight: 300,
        margins: 10,
      });

      var portfolioFilters = $('#filters');

      portfolioFilters.on('click', 'li', function () {
        portfolioFilters.find('li').removeClass('active');
        $(this).addClass('active');
        var filterValue = $(this).attr('data-filter');
        $worksGrid.isotope({ filter: filterValue });
        $window.trigger('resize');
      });

      $('#fullpage').fullpage({
        sectionSelector: 'section',
        menu: '#menu',
        lockAnchors: true,
        navigation: true,
        navigationPosition: 'right',
        showActiveTooltip: false,
        slidesNavigation: false,
        slidesNavPosition: 'bottom',
        responsiveWidth: 768,
      });
    });
  }

  function initParallax() {
    $('.parallax-bg img').each(function (index, el) {
      var container = $(this).parent('.parallax-bg');
      var image = $(this).attr('src');

      $(container).css('background-image', 'url(' + image + ')');

      $(this).remove();
    });

    $('.parallax-wrapper').each(function (index, el) {
      var elOffset = $(el).parent().offset().top;
      var winTop = $window.scrollTop();
      var scrll = (winTop - elOffset) * 0.15;

      if ($(el).isOnScreen()) {
        $(el).css('transform', 'translate3d(0, ' + scrll + 'px, 0)');
      }
    });
  }

  function initNavbar() {
    if (
      !$firstSection.is('.hero-section, .parallax-section, .dark-bg, .colored-bg') &&
      $homeSlider.length === 0
    ) {
      $navBar.addClass('stick');
    }

    if ($firstSection.is('.parallax-section.text-dark')) {
      $body.addClass('light-slide');
    }

    $navBar.find('.navigation-menu>li').slice(-2).addClass('last-elements');

    $('.menu-toggle, .toggle-nav').on('click', function (event) {
      event.preventDefault();
      if ($window.width() < 992) {
        $(this).find('.hamburger').toggleClass('is-active');

        $('#navigation').slideToggle(400);
        $navBar.find('.cart-open').removeClass('opened');
      }
    });

    $.merge($navBar, $asideNav).on(
      'click',
      '.navigation-menu li.menu-item-has-children>a',
      function (e) {
        if ($window.width() < 992) {
          e.preventDefault();
          $(this).parent('li').toggleClass('opened').find('.submenu:first').slideToggle();
        }
      },
    );

    $('#navigation .navigation-menu a[data-scroll="true"]').on('click', function () {
      if ($window.width() < 992) {
        $('.menu-toggle').trigger('click');
      }
    });

    $body.scrollspy({
      target: '#navigation',
    });

    $navBar.on('click', '.cart-open>a', function (event) {
      if ($window.width() < 992) {
        event.preventDefault();
        event.stopPropagation();

        if ($('#navigation').is(':visible')) {
          $('.menu-toggle').trigger('click');
        }

        $(this).parent('.cart-open').toggleClass('opened');
      }
    });
  }

  function initScroll() {
    $window
      .on('scroll', function () {
        if (
          $firstSection.is(
            '.hero-section, .parallax-section, .dark-bg, .home-section, .colored-bg, .gradient-bg',
          ) ||
          $homeSlider.length
        ) {
          if ($window.width() > 991) {
            if ($window.scrollTop() >= 150) {
              $navBar.addClass('stick');
            } else {
              $navBar.removeClass('stick');
            }

            if ($firstSection.hasClass('section-bordered')) {
              if ($window.scrollTop() <= 20) {
                $body.addClass('top-spacing');
              } else {
                $body.removeClass('top-spacing');
              }
            }
          }
        }

        initParallax();
      })
      .trigger('scroll');
  }

  function initVivus() {
    $('.animated-icon').each(function (index, el) {
      var startAt = $(el).parents('[data-animation]').length ? 'manual' : 'inViewport';
      if ($(el).parents('#fullpage').length) {
        startAt = 'autostart';
      }
      var delay =
        $(el).parents('[data-animation]').length && $window.width() > 767
          ? $(el).parents('[data-animation]').data('delay')
          : 0;
      var duration = 200;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        duration = 1;
      }
      new Vivus(el, {
        file: $(el).data('icon'),
        start: startAt,
        duration: duration,
        onReady: function onReady(obj) {
          if ($(el).hasClass('gradient-icon')) {
            var colors = $(el).data('gradients')
              ? $(el).data('gradients').replace(' ', '').split(',')
              : ['#cf93ff', '#00c3da'];
            var xmlns = 'http://www.w3.org/2000/svg';
            var grad = document.createElementNS(xmlns, 'linearGradient');
            var uid = 'grad-' + MAIN.guid(6);
            grad.setAttributeNS(null, 'id', uid);
            grad.setAttributeNS(null, 'gradientUnits', 'userSpaceOnUse');

            var stop1 = document.createElementNS(xmlns, 'stop');
            stop1.setAttributeNS(null, 'offset', 0);
            stop1.setAttributeNS(null, 'stop-color', colors[0]);

            var stop2 = document.createElementNS(xmlns, 'stop');
            stop2.setAttributeNS(null, 'offset', 100);
            stop2.setAttributeNS(null, 'stop-color', colors[1]);

            grad.append(stop1, stop2);

            $(obj.el).prepend(grad);
            obj.el.setAttributeNS(null, 'stroke', 'url(#' + uid + ')');
            $(obj.map).each(function (index, item) {
              item.el.setAttributeNS(null, 'stroke', 'url(#' + uid + ')');
            });
          }

          if ($(el).data('custom-color')) {
            var customColor = $(el).data('custom-color');
            obj.el.setAttributeNS(null, 'stroke', customColor);
            $(obj.map).each(function (index, item) {
              item.el.setAttributeNS(null, 'stroke', customColor);
            });
          }

          if ($(el).parents('[data-animation]')) {
            $(el)
              .parents('[data-animation]')
              .appear(function () {
                setTimeout(function () {
                  obj.play();
                }, delay);
              });
          }
        },
      });
    });
  }

  function initGeneral() {
    $('a[data-scroll="true"]').on('click', function () {
      if (
        location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
        location.hostname === this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html,body').animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
          );
          return false;
        }
      }
    });

    $('.open-search-form').on('click', function (e) {
      e.preventDefault();
      $searchModal.addClass('active');
      $body.addClass('modal-open');
      $navBar.find('.cart-open').removeClass('opened');
      setTimeout(function () {
        $('.search-form .form-control').focus();
      }, 500);
    });

    $('#close-search-modal').on('click', function (e) {
      e.preventDefault();
      $searchModal.removeClass('active');
      $body.removeClass('modal-open');
    });

    $('.bg-img, .thumb-placeholder, #navbar .product-list .product-thumbnail>img').each(function (
      index,
      el,
    ) {
      var image = $(el).attr('src');
      $(el)
        .parent()
        .css('background-image', 'url(' + image + ')')
        .addClass('img-cover');

      if ($(el).parent().hasClass('card-post-wrapper')) {
        $(el).parent().addClass('dark-bg');
      }

      $(el).remove();
    });

    $('.typed-words').each(function (index, el) {
      $(el).typed({
        strings: $(el).data('strings'),
        startDelay: 0,
        typeSpeed: 0,
        backDelay: 1500,
        backSpeed: 0,
        loop: true,
      });
    });

    if ($('#footer').length) {
      $('#wrapper>section:last').addClass('last-section');
    }

    $('.progress-bar').appear(function () {
      $(this).css('width', $(this).data('progress') + '%');
      $(this)
        .parents('.skill')
        .find('.skill-perc')
        .css('right', 100 - $(this).data('progress') + '%');
    });

    $('.testimonials-slider').slick({
      dots: true,
      prevArrow:
        '<button type="button" class="slick-prev"><i class="hc-arrow-round-back"></i></button>',
      nextArrow:
        '<button type="button" class="slick-next"><i class="hc-arrow-round-forward"></i></button>',
    });

    $('[data-gradients]')
      .not('.animated-icon')
      .each(function (index, el) {
        var colors = $(el).data('gradients').replace(' ', '').split(',');
        $(el).prepend(
          '<div class="gradient-bg-wrapper"><div class="gradient-bg-element"></div></div>',
        );
        $(el)
          .find('.gradient-bg-element')
          .css(
            'background',
            'linear-gradient(90deg, ' + colors[0] + ' 0%, ' + colors[1] + ' 100%)',
          );
      });

    $('[data-custom-bg]').each(function (index, el) {
      var bgColor;
      if ($(el).hasClass('team-overlay')) {
        bgColor = $(el).data('custom-bg');
        $(el).css('background-color', MAIN.hexToRGBA(bgColor, 0.9));
      } else if (
        $(el).data('bg-opacity') ||
        $(el).hasClass('slide-wrap') ||
        $(el).hasClass('image-overlay')
      ) {
        bgColor = $(el).data('custom-bg');
        var bgOpacity = $(el).data('bg-opacity') || 0.5;
        $(el).css('background-color', MAIN.hexToRGBA(bgColor, bgOpacity));
      } else {
        $(el).css('background-color', $(el).data('custom-bg'));
      }
    });

    $('[data-custom-color]').each(function (index, el) {
      if (!$(el).hasClass('animated-icon')) {
        $(el).css('color', $(el).data('custom-color'));
      }
    });

    $('[data-negative-margin]').each(function (index, el) {
      $(el).css('margin-top', -$(el).data('negative-margin'));
    });

    $('.product-rating-stars').each(function (index, el) {
      $(el).css('width', $(el).data('width') + '%');
    });

    $('.section-skewed').each(function (index, el) {
      $(el).append('<div class="skewed-mask"><div class="mask-block"></div></div>');
      var bgColor;
      if ($(el).next('section').data('gradients')) {
        bgColor = $(el).next('section').find('.gradient-bg-element').css('background');
        $(el).find('.skewed-mask .mask-block').css('background', bgColor);
      } else {
        bgColor =
          $(el).next('section').css('background') || $(el).next('section').css('backgroundColor');
        $(el).find('.skewed-mask .mask-block').css('background', bgColor);
      }
    });

    $('.counter').appear(function () {
      var counter = $(this).find('.number-count');
      var toCount = counter.data('count');
      var delay = $(this).data('delay') || 0;

      setTimeout(function () {
        $(counter).countTo({
          from: 0,
          to: toCount,
          speed: 1000,
          refreshInterval: 50,
        });
      }, delay);
    });

    $('.toggle-nav').on('click', function (event) {
      if ($window.width() > 991) {
        $(this).find('.hamburger').toggleClass('is-active');
        $asideNav.toggleClass('is-active');
      }
    });

    $('.toggle-fs-menu').on('click', function (event) {
      $body.toggleClass('modal-open');
      $(this).toggleClass('menu-active');
      $(this).find('.hamburger').toggleClass('is-active');
      $(this).parents('.fs-menu-wrapper').find('.fullscreen-menu').toggleClass('is-active');
    });

    $('.fs-menu-wrapper')
      .on('click', '.navigation-menu >li.menu-item-has-children>a', function (e) {
        e.preventDefault();
        $('.navigation-menu>li').not($(this).parent('li')).find('.submenu').slideUp(300);
        $(this).parent('li').find('ul:first').slideToggle(300);
      })
      .on(
        'click',
        '.submenu>li.menu-item-has-children>a, .sub-menu>li.menu-item-has-children>a',
        function (e) {
          e.preventDefault();
          $('.navigation-menu .submenu>li').not($(this).parent('li')).find('.submenu').slideUp(300);
          $('.navigation-menu .submenu>li')
            .not($(this).parent('li'))
            .find('.sub-menu')
            .slideUp(300);
          $(this).parent('li').find('ul:first').slideToggle(300);
        },
      );

    $asideNav
      .on('click', '.navigation-menu >li.menu-item-has-children>a', function (e) {
        if ($window.width() > 991) {
          e.preventDefault();
          $('.navigation-menu>li').not($(this).parent('li')).find('.submenu').slideUp(300);
          $(this).parent('li').find('ul:first').slideToggle(300);
        }
      })
      .on(
        'click',
        '.submenu>li.menu-item-has-children>a, .sub-menu>li.menu-item-has-children>a',
        function (e) {
          if ($window.width() > 991) {
            e.preventDefault();
            $('.navigation-menu .submenu>li')
              .not($(this).parent('li'))
              .find('.submenu')
              .slideUp(300);
            $('.navigation-menu .submenu>li')
              .not($(this).parent('li'))
              .find('.sub-menu')
              .slideUp(300);
            $(this).parent('li').find('ul:first').slideToggle(300);
          }
        },
      );

    $('#particle-canvas').particleground({
      dotColor: '#ffffff',
      lineColor: '#7b7b7b',
      particleRadius: 6,
      curveLines: true,
      density: 10000,
      proximity: 110,
    });
  }

  function initAnimation() {
    $('[data-animation]').addClass('animated');
    if ($worksGrid.hasClass('enable-animation')) {
      $worksGrid.find('.work-item').addClass('animated');

      $worksGrid.appear(function () {
        var dataDelay = 0;
        var animationName = $(this).data('animation-name') || 'fadeInUp';
        var animationEnd =
          'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

        $(this)
          .find('.work-item')
          .each(function (index, el) {
            setTimeout(function () {
              $(el)
                .addClass(animationName)
                .one(animationEnd, function () {
                  $(this).removeClass('animated ' + animationName);
                });
            }, dataDelay);
            dataDelay += 200;
          });
      });
    }

    $('[data-animation]').each(function (index, el) {
      var delay = $(el).data('delay') || 0;
      if ($window.width() < 768) {
        delay = 0;
      }
      $(el).appear(
        function () {
          setTimeout(function () {
            $(el).addClass($(el).data('animation'));
          }, delay);
        },
        { accX: 0, accY: 0 },
      );
    });
  }

  function initSlick() {
    $('.carousel').each(function (index, el) {
      var dataOptions = $(this).data('slick') || {};
      if (dataOptions.slidesToShow === 1) {
        dataOptions.mdItems = dataOptions.mdItems || 1;
        dataOptions.smItems = dataOptions.smItems || 1;
        dataOptions.xsItems = dataOptions.xsItems || 1;
      }
      var playSpeed = 3000;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        playSpeed = 1000;
      }
      $(el).slick({
        autoplay: true,
        autoplaySpeed: playSpeed,
        pauseOnFocus: false,
        pauseOnHover: false,
        arrows: false,
        swipeToSlide: true,
        infinite: true,
        prevArrow:
          '<button type="button" class="slick-prev"><i class="hc-arrow-round-back"></i></button>',
        nextArrow:
          '<button type="button" class="slick-next"><i class="hc-arrow-round-forward"></i></button>',
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: dataOptions.mdItems || 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: dataOptions.smItems || 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: dataOptions.xsItems || 1,
            },
          },
        ],
      });
    });
    $('.images-gallery').not('#product-slider').slick({
      autoplay: true,
      arrows: true,
      dots: false,
      swipeToSlide: true,
      prevArrow:
        '<button type="button" class="slick-prev"><i class="hc-arrow-round-back"></i></button>',
      nextArrow:
        '<button type="button" class="slick-next"><i class="hc-arrow-round-forward"></i></button>',
    });
    $('#product-slider-nav').slick({
      arrows: true,
      dots: false,
      infinite: true,
      touchMove: false,
      asNavFor: '#product-slider',
      vertical: true,
      verticalSwiping: true,
      slidesToShow: 3,
      focusOnSelect: true,
      prevArrow: '<button type="button" class="slick-prev"><i class="hc-arrow-up"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="hc-arrow-down"></i></button>',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            vertical: false,
            slidesToShow: 3,
            prevArrow:
              '<button type="button" class="slick-prev"><i class="hc-arrow-round-back"></i></button>',
            nextArrow:
              '<button type="button" class="slick-next"><i class="hc-arrow-round-forward"></i></button>',
          },
        },
      ],
    });
    $('#product-slider').slick({
      arrows: true,
      dots: false,
      swipeToSlide: true,
      asNavFor: '#product-slider-nav',
      prevArrow:
        '<button type="button" class="slick-prev"><i class="hc-arrow-round-back"></i></button>',
      nextArrow:
        '<button type="button" class="slick-next"><i class="hc-arrow-round-forward"></i></button>',
    });
  }

  function initContactForm() {
    var requiredInputs = $('#contact-form')
      .find('input[data-required], textarea[data-required]')
      .toArray();

    $('#form-btn').click(function (event) {
      event.preventDefault();
      var isValidForm = true;

      requiredInputs.forEach(function (element, index) {
        if (!$(element).val()) {
          $(element).parents('.form-group').addClass('has-error');
          isValidForm = false;

          if ($(element).attr('id') === 'name') {
            document.getElementById('name-error').innerHTML = t(
              'contact_form.name_required_error',
              'O campo do nome deverá ser preenchido.',
            );
          }
          if ($(element).attr('id') === 'email') {
            document.getElementById('email-error').innerHTML = t(
              'contact_form.email_required_error',
              'O campo do email deverá ser preenchido.',
            );
          }
          if ($(element).attr('id') === 'form-message') {
            document.getElementById('form-message-error').innerHTML = t(
              'contact_form.message_required_error',
              'O campo da mensagem deverá ser preenchido.',
            );
          }
        } else if (
          $(element).attr('id') === 'email' &&
          !$(element)
            .val()
            .match(/^\S+@\S+\.\S+$/)
        ) {
          $(element).parents('.form-group').addClass('has-error');
          isValidForm = false;

          document.getElementById('email-error').innerHTML = t(
            'contact_form.email_invalid_error',
            'Formato de email inválido (ex. exemplo@mail.pt)',
          );
        } else {
          $(element).parents('.form-group').removeClass('has-error');
        }
      });

      if (isValidForm) {
        var encode = function encode(data) {
          return Object.keys(data)
            .map(function (key) {
              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        };
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'contact',
            name: $('#name').val(),
            email: $('#email').val(),
            phone: $('#phone-number').val(),
            message: $('#form-message').val(),
          }),
        })
          .then(function () {
            var message =
              $('#contact-form').data('success-text') ||
              t('contact_form.success_message', 'Mensagem enviada');
            var succesTemplate =
              '<div role="alert" class="alert alert-success alert-colored">' + message + '</div>';
            $('#contact-form input, #contact-form textarea, #contact-form button').attr(
              'disabled',
              'disabled',
            );
            $('#contact-form .alert').fadeOut(300);
            $(succesTemplate).insertBefore($('#contact-form .btn'));
          })
          .catch(function () {
            var message =
              $('#contact-form').data('error-text') ||
              t(
                'contact_form.unknown_error_message',
                'Ocorreu um problema no envio da sua mensagem, tente mais tarde.',
              );
            var errorTemplate =
              '<div role="alert" class="alert alert-danger alert-colored">' + message + '</div>';
            $('#contact-form .alert').fadeOut(300);
            $(errorTemplate).insertBefore($('#contact-form .btn'));
          });
      }
    });

    $('#contact-form input, #contact-form textarea').on('keyup', function (event) {
      event.preventDefault();
      if (event.key !== 'Enter' && $(this).val()) {
        $(this).parent('.form-group').removeClass('has-error');
        if ($(this).attr('id') === 'name') {
          document.getElementById('name-error').innerHTML = '';
        }
        if ($(this).attr('id') === 'email') {
          document.getElementById('email-error').innerHTML = '';
        }
        if ($(this).attr('id') === 'form-message') {
          document.getElementById('form-message-error').innerHTML = '';
        }
      }
    });
  }

  function initCustom() {
    // Your code here.
  }

  function init() {
    $.fn.isOnScreen = function () {
      var viewport = {};
      viewport.top = $window.scrollTop();
      viewport.bottom = viewport.top + $window.height();
      var bounds = {};
      bounds.top = this.offset().top;
      bounds.bottom = bounds.top + this.outerHeight();
      return bounds.top <= viewport.bottom && bounds.bottom >= viewport.top;
    };

    initGeneral();
    initContactForm();
    initVivus();
    initNavbar();
    initScroll();
    initLoad();
    initAnimation();
    initSlick();
    initCustom();
  }

  init();
}
