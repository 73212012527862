import React from 'react';
import { Link, GatsbyLinkProps } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '../constants';

function LocalizedLink<TState>({
  to,
  ...restProps
}: Omit<GatsbyLinkProps<TState>, 'ref'>): React.ReactElement {
  const { i18n } = useTranslation();
  const urlLangPrefix = i18n.language !== DEFAULT_LANG ? '/' + i18n.language : '';
  return <Link to={urlLangPrefix + to} {...restProps}></Link>;
}

export default LocalizedLink;
