import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <footer id="footer" className="hidden">
      <div className="footer-copy">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <ul className="social-list">
                <li className="social-item-facebook">
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/MoscaDigital">
                    <i className="hc-facebook" />
                  </a>
                </li>
                <li className="social-item-linkedin">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/mosca-digital/"
                  >
                    <i className="hc-linkedin" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm-6">
              <div className="copy-text">
                <p>{t('footer.copyright', '© Copyright Mosca Digital 2020')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
