/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import initIntro from '../initIntro';
// @ts-ignore
import __main from '../main';
import Header from './Header';
import Footer from './Footer';
import FirstRenderContext from './FirstRenderContext';

interface LayoutProps {
  noNav?: boolean;
  children: React.ReactNode;
  className?: string;
}

declare const jQuery: unknown;

const Layout = ({ noNav = false, children, className }: LayoutProps): React.ReactElement => {
  const { t } = useTranslation();
  const isFirstRender = useContext(FirstRenderContext);

  useEffect(() => {
    const isHomepage = !!document.querySelector('#intro');
    const skipIntro =
      !isHomepage ||
      !isFirstRender ||
      (!!window.sessionStorage && window.sessionStorage.getItem('mainReached') === 'yes') ||
      window.location.search.indexOf('NOINTRO') !== -1;
    if (!isHomepage) {
      document.querySelector('#footer')!.classList.remove('hidden');
      __main(jQuery, t);
    } else if (skipIntro) {
      document.querySelector('#main')!.classList.remove('hidden');
      document.querySelector('#footer')!.classList.remove('hidden');
      __main(jQuery, t);
    } else {
      document.querySelector('#intro')!.classList.remove('invisible');
      document.querySelector('#interstitial')!.classList.remove('invisible');
      initIntro(t);
    }
  }, []);

  return (
    <>
      {!noNav && <Header></Header>}
      <div className={className}>{children}</div>
      <Footer></Footer>
      <div className="footer-spacer"></div>
    </>
  );
};

export default Layout;
