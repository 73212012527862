import React from 'react';
import { useTranslation } from 'react-i18next';
import LocalizedLink from './LocalizedLink';

import styles from './Header.module.scss';

const Header = (): React.ReactElement => {
  const { t, i18n } = useTranslation();

  return (
    <nav id="navbar">
      <div className="navbar-wrapper">
        <div className="container">
          <div className="logo">
            <LocalizedLink to="/">
              <img className="logo-light" src="/images/logo-light.png" alt="Logo Light Version" />
              <img className="logo-dark" src="/images/logo-dark.png" alt="Logo Dark Version" />
            </LocalizedLink>
          </div>
          <div className="menu-extras">
            <div className="menu-item">
              <div className="header-socials">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/MoscaDigital"
                    >
                      <i className="hc-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/mosca-digital/"
                    >
                      <i className="hc-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="menu-item">
              <div className="nav-toggle">
                <a className="menu-toggle" href="#">
                  <div className="hamburger">
                    <div className="hamburger-box">
                      <div className="hamburger-inner" />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div id="navigation">
            <div className={styles.languageSwitcher}>
              <div className={styles.languageSwitcherInner}>
                <span
                  className={i18n.language === 'pt' ? styles.selectedLang : ''}
                  onClick={() => {
                    const path = window.location.pathname;
                    if (path.startsWith('/en')) {
                      window.location.href = path.substring(3);
                    }
                  }}
                >
                  PT
                </span>
                &nbsp;|&nbsp;
                <span
                  className={i18n.language === 'en' ? styles.selectedLang : ''}
                  onClick={() => {
                    const path = window.location.pathname;
                    if (!path.startsWith('/en')) {
                      window.location.href = '/en' + path;
                    }
                  }}
                >
                  EN
                </span>
              </div>
            </div>
            <ul className="navigation-menu nav">
              {/* <li class="">
              <a data-scroll="true" href="https://influenciadores.moscadigital.pt"
              >Influenciadores</a
              >
            </li> */}
              <li>
                <LocalizedLink data-scroll="true" to="/#quem-somos">
                  {t('navbar.who_we_are', 'Somos')}
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink data-scroll="true" to="/#que-fazemos">
                  {t('navbar.what_we_do', 'Fazemos')}
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink data-scroll="true" to="/#contact-us">
                  {t('navbar.contacts', 'Contacte-nos')}
                </LocalizedLink>
              </li>
              <li className={styles.blogMenuEntry}>
                <LocalizedLink to="/blog">{t('navbar.blog', 'Blog')}</LocalizedLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
