/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TFunction } from 'i18next';
// @ts-ignore
import __main from './main';

declare const jQuery: unknown;
declare const $: any;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function initIntro(t: TFunction): void {
  const FIRST_TIME_TEXT = [t('intro.first_time_text', 'Olá.')].reverse();
  const REPEATING_TEXT = t('intro.repeating_text', 'Vou repetir.').split(' ').reverse();
  const NERD_TEXT = t(
    'intro.nerd_text',
    'Quer um site estático Gatsby.js alimentado por um CMS Headless Sanity.io alojado no Netlify segundo a arquitectura JAMStack? Talvez uma PWA em React que usa Apollo para falar GraphQL com um backend serverless em AWS Lambda? Ou uma app React Native Android + iOS com backend Express e MongoDB managed com Kubernetes na Digital Ocean? Ou quer melhorar a sua UI através de UX e gamification por forma a elevar os seus KPIs? Isto tudo fazendo chegar a mensagem ao mundo através de uma estratégia de comunicação digital faseada, com um funil de conversão, que através de SEM e SEO obtenha um CPC reduzido para Landing Page e uma CTR elevada. Sem nunca esquecer uma estratégia de Google Adwords Long-Tail Keywords, de modo a optimizar custos.',
  );
  const MAX_CHAR_PER_LINE = 20;
  let writing = true;

  const state = {
    lines: ['', '', ''],
  };

  let firstTime = FIRST_TIME_TEXT.length;
  let repeating = 0;
  let curPos = 0;
  let curLine = 0;

  function updateLines() {
    const lines = state.lines;
    for (let i = 0; i < lines.length; i++) {
      document.querySelector('#intro-text' + (i + 1))!.textContent = lines[i];
    }
  }

  function getNextWord(): [string, number] {
    if (firstTime > 0) {
      firstTime--;
      return [FIRST_TIME_TEXT[firstTime], 0];
    } else if (repeating > 0) {
      repeating--;
      return [REPEATING_TEXT[repeating], 0];
    }
    let word = '';
    let i = 0;
    for (i = curPos; i < NERD_TEXT.length; i++) {
      if (NERD_TEXT[i] !== ' ') {
        word += NERD_TEXT[i];
      } else {
        break;
      }
    }
    const nextPos = i === NERD_TEXT.length ? 0 : i + 1;
    if (nextPos === 0) {
      repeating = REPEATING_TEXT.length;
    }
    return [word, nextPos];
  }

  function shiftLinesUp(): void {
    const lines = state.lines;
    for (let i = 1; i < lines.length; i++) {
      lines[i - 1] = lines[i];
    }
    lines[lines.length - 1] = '';
  }

  function writeWord(word: string): void {
    const textToWrite = (state.lines[curLine] ? ' ' : '') + word;
    let i = 0;
    const interval = setInterval(function () {
      if (i < textToWrite.length) {
        const linesCopy = state.lines.slice();
        linesCopy[curLine] += textToWrite[i];
        state.lines = linesCopy;
        updateLines();
        i++;
      } else {
        clearInterval(interval);
        writeText();
      }
    }, 80);
  }

  function writeText(): void {
    if (writing) {
      const lines = state.lines;
      const getNextWordOutput = getNextWord();
      const word = getNextWordOutput[0];
      const nextPos = getNextWordOutput[1];
      if (lines[curLine].length + word.length + 1 > MAX_CHAR_PER_LINE) {
        if (curLine === lines.length - 1) {
          shiftLinesUp();
        } else {
          curLine++;
        }
      }

      curPos = nextPos;
      writeWord(word);
    }
  }

  const translateButton = document.querySelector('#translateButton img')!;

  function handleTranslateButtonClick() {
    translateButton.removeEventListener('click', handleTranslateButtonClick);
    document.querySelector('#intro')!.classList.add('intro-animated');
    setTimeout(function () {
      document.querySelector('#intro')!.classList.add('hidden');
      writing = false;
      document.querySelector('#interstitial')!.classList.add('interstitial-animated');
      document.querySelector('#main')!.classList.remove('hidden');
      document.querySelector('#footer')!.classList.remove('hidden');
      __main(jQuery, t);

      setTimeout(function () {
        setTimeout(function () {
          document.querySelector('#interstitial')!.classList.add('hidden');
          document.querySelector('body')!.style.overflow = 'auto';
          window.sessionStorage.setItem('mainReached', 'yes');

          $(document).ready(function () {
            'use strict';

            $.cookieBar({
              message: t(
                'cookie_bar.text',
                'Somos obrigados a informar que este site tem uma cookie, mas não se preocupe que pode sempre acompanhar com um copinho de leite',
              ),
              fixed: true,
              policyButton: false,
            });
          });
        }, 1000); // time until end of interstitial fade animation
      }, 3000); // time until end of interstitial text fade animation
    }, 4000); // time until end of intro fade animation
  }

  function loadPointingHandAnimation() {
    const pointingHand = $('#pointing-hand');
    pointingHand.css('animation-name', 'move');
    pointingHand.css('animation-delay', '5s');
    pointingHand.css('animation-duration', '0.5s');
    pointingHand.css('animation-timing-function', 'ease-in');
    pointingHand.css('animation-iteration-count', 'infinite');
    pointingHand.css('animation-direction', 'alternate');
  }

  if ($) {
    loadPointingHandAnimation();
  } else {
    window.onload = loadPointingHandAnimation;
  }

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    document.getElementById('intro-max')!.addEventListener('scroll', preventMotion, false);
    document.getElementById('intro-max')!.addEventListener('touchmove', preventMotion, false);

    function preventMotion(event: Event) {
      // window.scrollTo(0, 0);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  // Translate click handler
  document.querySelector('body')!.style.overflow = 'hidden';
  translateButton.addEventListener('click', handleTranslateButtonClick);

  writeText();

  // Fit font size to bubble
  const nerdEl = document.getElementById('talking-head-wrapper')!;
  const nerdElWidth = nerdEl.offsetWidth;
  nerdEl.style.fontSize = nerdElWidth / 25 + 'px';
}
